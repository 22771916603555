<template>
  <div>
    <div class="sctp-container sctp-pad-b20 container clear">
      <div class="pad-tb10">
        <el-breadcrumb separator=">">
          <el-breadcrumb-item>法律声明/隐私政策</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <!--  左侧菜单  -->
      <div class="left-menu sctp-fl sctp-bg-white">
        <template v-for="(group,index) in linkArray">
          <router-link :to="'/protocol/' + (Protocol + 1)">
            <h3 class="title">{{ group.name }}</h3>
          </router-link>
        </template>
      </div>
      <div class="right-content sctp-fl sctp-bg-white pad15" style="min-height: 50vh;">
        <div class="rich-text" v-html="protocolContent"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {common} from "@/apis/index";

export default {
  name: 'protocolIndex',
  components: {},
  props: ['index'],
  data() {
    return {
      agreementName: null,
      linkArray: [
        {
          name: '法律声明',
          url: '法律声明'
        },
        {
          name: '隐私政策',
          url: '隐私政策'
        }
      ],
      protocolContent: null
    }
  },
  watch: {
    index: {
      handler(value, oldValue) {
        if (value || value === 0) {
          this.agreementName = this.linkArray[value - 1].url
        }
      }
    },
    agreementName: {
      handler(value, oldValue) {
        if (value || value === 0) {
          this.getProtocol()
        }
      }
    }
  },
  methods: {
    getProtocol() {
      common.loadProtocol({
        protocolName: this.agreementName
      }).then(res => {
        const {data} = res
        this.protocolContent = data
      })
    }
  },
  computed: {},
  beforeMount() {
    this.agreementName = this.linkArray[this.index - 1].url
  }
}
</script>

<style scoped lang="scss">
.container {
  min-height: 50vh;

  .left-menu {
    width: 180px;
    margin-right: 20px;
    padding: 10px 0;

    & a:last-child h3:after {
      content: none;
    }

    h3 {
      line-height: 40px;
      padding-left: 24px;
      font-weight: bold;
      cursor: pointer;
      position: relative;

      &:after {
        position: absolute;
        content: '';
        display: block;
        left: 15px;
        top: 0;
        right: 15px;
        bottom: 0;
        border-bottom: 1px dotted #333;
      }
    }
  }

  .right-content {
    width: calc(100% - 200px);
  }
}
</style>
